

h1 {
    font-size: 2rem; 
    color: #333;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 8px; 
    margin: auto;
    padding-top: 1rem;
    max-width: fit-content;
    
}

.nextbuy-header {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;  /* A modern, clean font-family */
    font-weight: 300;  /* Lighter font weight for modern feel */
    text-align: center;
    font-size: 2.3rem;  /* Adjusted size */
    color: #333;      /* Slightly off-black for a softer look */
    text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);  /* Increased shadow depth */
    letter-spacing: 1px;  /* Slight letter spacing for added style */
    transition: transform 0.2s; /* Subtle hover effect */
    font-style: italic;  /* Adding italics */
}

.nextbuy-header:hover {
    transform: scale(1.02); /* Subtle enlarge effect on hover */
    cursor: pointer;
}


.intro {
    font-size: 1.25rem;
    color: #777;
    text-align: center;
    font-style: italic; 
    max-width: 80%; 
    margin-left: auto;
    margin-right: auto;
}


/* Grid Styles */


.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 13px;
    align-items: center;  /* Center content vertically */
    /* justify-items: center;  */
    
}


/* Card Styles */
.card {
    border: 1px solid #e0e0e0; /* Lighter border for better aesthetics */
    padding: 10px;
    text-align: center;
    margin: 20px;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0px 0px 15px rgba(0,0,0,0.1); /* Shadow effect */
    transition: 0.3s; 
    display: flex !important;
    align-items: center;
}
h2 {
    font-size: 1.65rem !important;
    padding: 0;
    margin: 0;
    margin-bottom: 0 !important;
    
}

p {
    margin: 0;
    padding: 0;
    font-size: 0.95rem;
    margin-bottom: 0 !important;
    
}

.next-buy {
    
   
    color: rgb(234, 30, 30);
}
.description {
    font-style: italic;
    color: rgb(143, 143, 143)
}
.price {
    font-size: 1rem;
    padding: 0;
    margin: 0;
}
.card:hover {
    transform: scale(1.03); /* Slight scale on hover for interactive feel */
}

.button1 {
    background-image: linear-gradient(to right, #92da92cf, #28c328);
    color: #fff; /* White text */
    border: none;
    padding: 8px 16px;
    margin: 3px 3px;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Shadow effect */
}

.button1:hover {
   
    background-image: linear-gradient(to right, #5bb55b, #13a513);
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Shadow effect */
    transform: scale(1.02); /* Slight scale on hover for interactive feel */
}

.button1:active {
    background-image: linear-gradient(to right, #339933a5, #0f850f9e);
    box-shadow: 0px 0px 5px rgba(0,0,0,0.1); /* Less shadow to simulate a pressed button */
    transform: scale(0.98); /* Slight scale down to simulate a pressed button */
}



/* Styling the nr on the top right corner */

.quantity-indicator {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px 10px;
    font-size: 1em;
  }
  


/* Mobile Responsiveness */
@media (max-width: 768px) {
    .nextbuy-header {
        font-size: 1.7rem;  /* Adjusted size */
      
    }
    

    
    .intro {
        font-size: 1rem;
        color: #777;
        text-align: center;
        font-style: italic; 
        max-width: 85%; 
        margin-left: auto;
        margin-right: auto;
        
    }
    .grid {
        grid-template-columns: repeat(2, 46%); /* 2 products per row with each taking 45% width */
        margin: 15px 1px;
        width: 90%;
        max-width: 100%;
        max-height: 100%;
    }


    img {
        width: 100%;
        height: 150px;
    }
    /* Font */
    .product-name {
        margin: 0;
    }

    h2 {
        font-size: 1.1rem !important;
        padding: 0;
        margin: 0;
        margin-bottom: 0 !important;
        
    }
    
    
    
    .description {
        font-size: 0.75rem;
        font-style: italic;
        color: rgb(143, 143, 143)
    }
    .price {
        font-size: 0.9rem;
        padding: 0;
        margin: 0;
    }
    .button1 {
        margin-bottom: 2px;
        padding: 5px 15px;
        
    }
    .add-card {
      
    }
   
  
    .card{
        margin: 0px;
        padding: 0px;
}
}

