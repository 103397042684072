.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Georgia, serif';
  background-color: #f5f5f5; /* light grey background */
  min-height: 100vh;
  text-align: center;
  width: 100%;
}

h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.profile-info, .profile-update, .profile-delete, .logout {
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px; /* you can adjust as needed */
  border-radius: 8px;
}

.profile-info p, .profile-update input {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.profile-update input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.profile-update button, .profile-delete button, .logout button {
  background-color: #007BFF;
  color: #fff;
  border: none;
  padding: 0.6rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.profile-update button:hover, .profile-delete button:hover, .logout button:hover {
  background-color: #0056b3;
}

.profile-delete button {
  background-color: #dc3545; /* red */
}

.profile-delete button:hover {
  background-color: #b22234; /* darker red */
}
