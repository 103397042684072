/* Navbar Styles */
*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
  overflow-y: scroll; /* This forces the vertical scrollbar to always be visible */
}


header {
  height: 80px;
  margin: 0;
  font-family: Georgia, serif;
  background-color: #343a40;  /* Dark background for navbar */
  padding: 15px 0px 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

main {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
footer {
  max-width: 100%;
}

nav {
  display: flex;
  justify-content: space-between;
  width: 65%;
 
  margin-left: 200px;
}

nav ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

nav li {
  margin: 0.8rem;
}

nav a {
  text-decoration: none;
  color: white;
  padding: 10px ;
  border-radius: 5px;
  transition: background-color 0.1s;
  font-size: 1rem;
}

nav a:hover {
  background-color: #495057;  /* Slightly lighter shade on hover */
}

.cart-number {
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
  color: red;

}
/* .cart-number {
  background-color: red;
  padding: 2px 5px;
  border-radius: 5px;
  color: white;
  font-size: 12px;
} */

/* Active link styling */
.active {
  color: rgb(255, 255, 255); /* Active link color */
  /* font-family: 1.1rem; */
  border-bottom: 2px solid rgb(255, 255, 255) !important; /* Add a bottom border to highlight the active link */
  padding: 0px 0px  !important; /* Add some padding to the bottom to prevent the border from overlapping with text */
}


.nav-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 5px 3px !important;
}

/* ... Your other styles ... */


.dropdown-full-height {
  height: 90vh; /* or whichever height you prefer */
  max-height: 90vh; 
  overflow-y: auto;


}
.dropdown-full-height .nav-link {
  font-size: 1rem;
}



/* Existing styles... */

/* For mobile and tablet screens */
@media (max-width: 768px) {
  
 

  nav {
    margin: 0;
    width: 85%;
    
  }
 
  nav li {
      margin-bottom: 10px;
    
      padding-bottom: 10px;
      margin: 0;
      font-size: 1rem;
      width: 25%;
  }
 

 

  nav a {
   
      padding: 5px; /* Reduce padding */
      font-size: 0.8rem; /* Reduce font size */
  }

  .cartNr {
      font-size: 0.9rem; /* Adjust cart number size */
  }
  .dropdown-full-height {
    inset: 0px 0px 0px 0px !important;
  }
  .Dropdown {
      margin-top: 10px; /* Space out the dropdown from the nav */
  }

  .DropdownToggle {
      font-size: 0.5rem; /* Adjust dropdown toggle size */
  }
}



@media (max-width: 570px) {
  nav {
    margin: 0;
  }
  nav a {
    font-size: 1.3rem;
    
    padding: 2px 12px;
  }
  
}

/* For smaller mobile screens */
@media (max-width: 480px) {
  nav ul {
   justify-content: flex-start;

   padding: 0 !important;
   margin: 0 !important;

}
 nav li {
     margin-bottom: 10px;
     width: 25%;
    
      padding: 0 ;
     margin: 0;
     font-size: 1.8rem;
     display: flex;
     justify-content: center;
 }

  nav li {
      font-size: 1.6rem;
      display: flex;
      flex-direction: row;  /* Further reduce the font size */
  }

  nav a {
    font-size: 1.3rem;
    
    padding: 2px 12px;
  }

  .cartNr {
      font-size: 0.8rem; /* Adjust cart number size */
  }

  .DropdownToggle {
      font-size: 0.7rem; /* Adjust dropdown toggle size */
  }



  .active {

    padding: 2px 10px  !important; /* Add some padding to the bottom to prevent the border from overlapping with text */
  }
  
  
  .nav-link-cart {
    padding: 0px !important;
  }
  
}





@media (max-width: 380px) { 
nav {
  width: 75%;
}

}