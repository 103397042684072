.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: white !important;
  padding: 20px !important;
  border-radius: 4px !important;
  width: 60% !important;
  max-width: 500px;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #f0f0f0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.header h2 {
  font-size: 18px;
  margin: 0;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  background-color: #f0f0f0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

@keyframes slideIn {
  from {
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.close-button, .footer-button {
  background-color: #28a745;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
}

.close-button:hover, .footer-button:hover {
  background-color: #1f7a33;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.close-icon {
  margin-right: 10px;
}

.message {
  color: #28a745;
  padding: 30px 5px;
  font-size: 20px !important;
}




/* Adjust width for small devices */
@media screen and (max-width: 768px) {
  .modal-content {
    width: 80% !important;
  }
}