.about-us-container {
  margin: 20px auto;
  max-width: 800px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: #f8f9fa; /* Light grey background for readability */
}

.about-us-title {
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Gap between icon and text */
}

.about-us-subtitle {
  font-size: 1.4em;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #444;
  display: flex;
  align-items: center;
  gap: 10px; /* Gap between icon and text */
}

.about-us-text {
  font-size: 1.1em;
  line-height: 1.6;
  color: #555;
}

.about-us-text ul {
  list-style-type: none; /* Remove bullet points */
  padding-left: 0;
}

.about-us-text li {
  display: flex;
  align-items: center;
  gap: 10px; /* Gap between icon and text */
}
