.checkout-container {
  width: 60%;
  max-width: 600px;
  margin: 30px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  background-color: #f4f4f4;
}


.checkout-header {
  display: flex;
  align-items: center;
}

.checkout-icon {
  margin-right: 10px;
  font-size: 2em; /* Adjust size as needed */
}

.checkout-container h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.checkout-container p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #555;
  text-align: center;
}

.input-group {
  margin-bottom: 0.8rem;
}

.input-group label {
  display: block;
  margin-bottom: 8px;
  color: #555;
  font-weight: bold;
}

.input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.input-group input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}


.purchase {
  width: 100%;
  display: flex;
  justify-content: center;
}
.purchase-button {
  width: 60%;
  margin-left: auto !important;
  margin-right: auto !important;
  padding: 10px 20px;
  font-size: 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.purchase-button:hover {
  background-color: #0056b3;
}


@media (max-width: 768px) {

  .checkout-container {
    width: 80%;}

}