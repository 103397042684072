.login-container {
  width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 90vh;
    background-color: #f4f4f4;
    bottom: 100px;
  }
  
  .login-title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .login-label,
  .login-input {
    margin-bottom: 0.8rem;
  }
  
  .login-input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-button {
    background-color: #007bff;
    color: #fff;
    padding: 0.5rem;
    border: none;
    border-radius: 4px;
   
  }  

  .login-register-link {
    font-family: 'Georgia, serif';
    font-size: 1rem;
    text-align: center;
    margin-top: 20px;
    color: #666;  /* A subtle gray color */
}

.login-register-link a {
    
    
    padding: 2px 4px;
    border-radius: 3px;
    transition: background-color 0.2s, color 0.2s;  /* Smooth transition for hover effect */
}

.login-register-link a:hover {
    background-color: #007bff;  /* The blue becomes the background on hover */
    color: #fff;  /* Text turns white on hover */
}
